import classNames from "classnames";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

const Loader = ({ fullscreen = false, size = 40 }) => (
  <Box className={classNames({ "fullscreen-loader": fullscreen })}>
    <CircularProgress size={size} />
  </Box>
);

export default Loader;
