import * as React from "react";

import { Generic } from "src/@types/Category";

import agent from "src/helpers/apiAgent";

import useAuth from "src/hooks/useAuth";
import useCourse from "src/hooks/useCourse";

// Set defaults for reuse
const DEFAULTS = {
  loading: false,
  events: [],
  calendarEvents: [],
  stats: {
    studentImpactIndex: { average: 0, courses: [] },
    contentQualityIndex: { average: 0, courses: [] },
    curriculumCompleteness: { average: 0, courses: [] },
    assignmentMatrix: {
      courseCode: "",
      courseName: "",
      distribution: [],
      section: "",
      weightage: "",
    },
  },
  eventPropGetter: (event: Generic) => ({
    style: {
      backgroundColor: "",
    },
  }),
  getEventsOnSelection: (eventDetails: object, date: string) => [],
};
const DashboardContext = React.createContext(DEFAULTS);

const DashboardProvider: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
}) => {
  const { isLoggedIn, user } = useAuth();
  const { activeGrade } = useCourse();

  const [loading, setLoading] = React.useState(DEFAULTS.loading);
  const [stats, setStats] = React.useState(DEFAULTS.stats);
  const [events, setEvents] = React.useState(DEFAULTS.events);
  const [calendarEvents, setCalendarEvents] = React.useState(DEFAULTS.events);

  React.useEffect(() => {
    const transformed = [];
    Object.entries(events)?.forEach(([date, values]) => {
      // transformed?.push({
      //   allDay: true,
      //   id: `${Date.now()}`,
      //   end: new Date(date),
      //   start: new Date(date),
      //   title: `${values?.length}`,
      // });

      const assignments = values?.filter((v) => v?.type === 1)?.length;
      const exams = values?.filter((v) => v?.type === 2)?.length;
      const quizes = values?.filter((v) => v?.type === 3)?.length;

      if (assignments) {
        transformed?.push({
          allDay: true,
          id: `${Date.now()}-1`, // Assignments
          end: new Date(date),
          start: new Date(date),
          title: `${assignments}`,
          priority: "assignment",
        });
      }
      if (exams || quizes) {
        transformed?.push({
          allDay: true,
          id: `${Date.now()}-2`, // Exams & Quizzes
          title: `${exams + quizes}`,
          end: new Date(date),
          start: new Date(date),
          priority: "exams",
        });
      }
    });
    // type
    setCalendarEvents(transformed);
  }, [events]);

  const getUpcomingEvents = React.useCallback(async () => {
    try {
      setLoading(true);
      const response = await agent.Dashboard.getUpcomingEvents();
      if (response?.code === 200) {
        setEvents(response?.data);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }, []);

  const getStats = React.useCallback(async () => {
    const getStats = async () => {
      try {
        setLoading(true);
        const response = await agent.Dashboard.getTeacherDashboardStats(
          user?.profile?.id,
          activeGrade?.id
        );

        // if (response.code === 200) {
        setStats(response); // will change to response?.data
        // }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    };
    if (user && user?.profile?.id && activeGrade?.id) {
      getStats();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.profile?.id, activeGrade]);

  React.useEffect(() => {
    if (isLoggedIn) {
      getStats();
      getUpcomingEvents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUpcomingEvents, getStats, isLoggedIn]);

  const getEventsOnSelection = (eventDetails, date) => {
    const type = eventDetails?.id?.toString()?.split("-")[1];
    let eventsOfDateAndType = [];
    if (type === "1") {
      eventsOfDateAndType = events[date]?.filter(
        (cl) => Number(type) === cl?.type
      );
    } else {
      eventsOfDateAndType = events[date]?.filter(
        (cl) => cl?.type === 2 || cl?.type === 3
      );
    }
    return eventsOfDateAndType;
  };

  const eventPropGetter = (event) => {
    let backgroundColor = "";
    if (event.priority === "assignment") {
      backgroundColor = "#90b0d7";
    } else if (event.priority === "exams") {
      backgroundColor = "#e8c842";
    } else {
      backgroundColor = "#ff6b4a";
    }

    return { style: { backgroundColor } };
  };

  const contextValues = {
    stats,
    events,
    loading,
    calendarEvents,
    eventPropGetter,
    getEventsOnSelection,
  };

  return (
    <DashboardContext.Provider value={contextValues}>
      {children}
    </DashboardContext.Provider>
  );
};

export { DashboardContext };
export default DashboardProvider;
