import { lazy, Suspense } from "react";
import { Route, Routes, Outlet, Navigate } from "react-router-dom";

import { SomeUserLoginDetails } from "src/@types/User";

import { LOCAL_USER } from "./constants/StorageKeys";
import { PUBLIC_ROUTES, PROTECTED_ROUTES } from "src/constants/Navigation";

import Loader from "src/components/Loader";

const RoutesWithLayout = lazy(() => import("src/components/RoutesWithLayout"));

// Public Routes
const Login = lazy(() => import("src/screens/Auth/Login"));
const SetPassword = lazy(() => import("src/screens/Auth/SetPassword"));
// Private Routes
const Dashboard = lazy(() => import("src/screens/Dashboard"));
const Course = lazy(() => import("src/screens/Course"));
const CourseDetails = lazy(() => import("src/screens/Course/CourseDetails"));
const TopicDetails = lazy(() => import("src/screens/Course/TopicDetails"));
const AddNewLecture = lazy(() => import("src/screens/Course/AddNewLecture"));
const Category = lazy(() => import("src/screens/Category"));
const CategoriesManagement = lazy(
  () => import("src/screens/Category/CategoriesManagement")
);
const Questions = lazy(() => import("src/screens/Questions"));
const QuestionsListing = lazy(
  () => import("src/screens/Questions/QuestionsListing")
);
const BulkQuestionsListing = lazy(
  () => import("src/screens/Questions/BulkQuestionsListing")
);
const QuestionDetails = lazy(
  () => import("src/screens/Questions/QuestionDetails")
);
const AddNewQuestion = lazy(() => import("./screens/Questions/AddNewQuestion"));
const AddBulkQuestions = lazy(
  () => import("./screens/Questions/AddBulkQuestions")
);
const Assignments = lazy(() => import("./screens/Assignment"));
const AssignmentsListing = lazy(
  () => import("./screens/Assignment/AssignmentListing")
);
const AssignmentSubmittedListing = lazy(
  () => import("./screens/Assignment/AssignmentSubmittedListing")
);
const AddNewAssignment = lazy(
  () => import("./screens/Assignment/AddNewAssignment")
);
const AssignmentPreview = lazy(
  () => import("./screens/Assignment/AssignmentPreview")
);
const AssignmentGrading = lazy(
  () => import("./screens/Assignment/AssignmentGrading")
);
const StudentsListing = lazy(() => import("./screens/Students"));
const StudentDetails = lazy(() => import("./screens/Students/StudentDetails"));

const PrivateRoutes = () => {
  const userData: string = localStorage.getItem(LOCAL_USER);
  let userDataParsed: SomeUserLoginDetails;

  if (userData !== null) {
    userDataParsed = JSON.parse(userData) as SomeUserLoginDetails;
  }

  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get("token");

  if (token) {
    return null;
  }

  return userDataParsed?.token ? (
    <Outlet />
  ) : (
    <Navigate to={PUBLIC_ROUTES.LOGIN} />
  );
};

const MainRoutes: React.FC = () => {
  return (
    <Suspense fallback={<Loader fullscreen />}>
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route path="/" element={<RoutesWithLayout />}>
            <Route path={PROTECTED_ROUTES.DASHBOARD} element={<Dashboard />} />
            <Route path={PROTECTED_ROUTES.CATEGORY} element={<Category />} />
            <Route
              path={PROTECTED_ROUTES.CATEGORY_MANAGEMENT}
              element={<CategoriesManagement />}
            />
            <Route path={PROTECTED_ROUTES.COURSE} element={<Course />} />
            <Route
              path={PROTECTED_ROUTES.COURSE_DETAILS}
              element={<CourseDetails />}
            />
            <Route
              path={PROTECTED_ROUTES.COURSE_TOPIC_DETAILS}
              element={<TopicDetails subject={{}} />}
            />
            <Route
              path={PROTECTED_ROUTES.COURSE_TOPIC_ADD_LECTURE}
              element={<AddNewLecture />}
            />
            <Route path={PROTECTED_ROUTES.QUESTIONS} element={<Questions />} />
            <Route
              path={PROTECTED_ROUTES.QUESTIONS_LISTING}
              element={<QuestionsListing />}
            />
            <Route
              path={PROTECTED_ROUTES.BULK_QUESTIONS_LISTING}
              element={<BulkQuestionsListing />}
            />
            <Route
              path={PROTECTED_ROUTES.QUESTIONS_DETAILS}
              element={<QuestionDetails />}
            />
            <Route
              path={PROTECTED_ROUTES.ADD_QUESTIONS}
              element={<AddNewQuestion />}
            />
            <Route
              path={PROTECTED_ROUTES.ADD_BULK_QUESTIONS}
              element={<AddBulkQuestions />}
            />
            <Route
              path={PROTECTED_ROUTES.ASSIGNMENT}
              element={<Assignments />}
            />
            <Route
              path={PROTECTED_ROUTES.ASSIGNMENT_LISTING}
              element={<AssignmentsListing />}
            />
            <Route
              path={PROTECTED_ROUTES.ASSIGNMENT_SUBMITTED_LISTING}
              element={<AssignmentSubmittedListing />}
            />
            {/* <Route
          path={PROTECTED_ROUTES.ASSIGNMENT_GRADING}
          element={<AssignmentGrading />}
        /> */}
            <Route
              path={PROTECTED_ROUTES.ADD_ASSIGNMENTS}
              element={<AddNewAssignment />}
            />
            <Route
              path={PROTECTED_ROUTES.PREVIEW_ASSIGNMENT}
              element={<AssignmentPreview />}
            />
            <Route
              path={PROTECTED_ROUTES.STUDENT}
              element={<StudentsListing />}
            />
            <Route
              path={PROTECTED_ROUTES.STUDENT_DETAILS}
              element={<StudentDetails />}
            />
            <Route
              path={PROTECTED_ROUTES.STUDENT_ASSIGNMENT_GRADING}
              element={<AssignmentGrading />}
            />
            <Route
              path={PROTECTED_ROUTES.STUDENT_PREVIEW_ASSIGNMENT}
              element={<AssignmentPreview submitted />}
            />
            <Route
              path={PROTECTED_ROUTES.ALL}
              element={<Navigate to={PROTECTED_ROUTES.DASHBOARD} />}
            />
          </Route>
          <Route
            path={PROTECTED_ROUTES.ALL}
            element={<Navigate to={PROTECTED_ROUTES.DASHBOARD} />}
          />
        </Route>
        <Route path={PUBLIC_ROUTES.LOGIN} element={<Login />} />
        <Route path={PUBLIC_ROUTES.SET_PASSWORD} element={<SetPassword />} />
        <Route path={PROTECTED_ROUTES.PRIVACY_POLICY} element={<div />} />
        <Route path={PROTECTED_ROUTES.TERMS} element={<div />} />
      </Routes>
    </Suspense>
  );
};

export default MainRoutes;
