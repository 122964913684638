import toast from "react-hot-toast";

import { containsHTML } from "./common";
import { LOCAL_USER } from "src/constants/StorageKeys";

const UNAUTH_STATUS = 401;

export const errorHandler = (error) => {
  try {
    if (error.status === UNAUTH_STATUS || error.code === UNAUTH_STATUS) {
      localStorage.clear();
      localStorage.removeItem(LOCAL_USER);
      window.dispatchEvent(new Event("storage"));
      toast.error("Session expired! Logging out.", {
        id: "session",
        icon: "🔑",
      });
      return;
    }
    if (error?.response?.body) {
      error = error.response.body;
    }
    if (containsHTML(error?.message || error)) {
      const tempElement = document.createElement("div");
      tempElement.innerHTML = error?.message || error;

      let extractedText =
        tempElement.textContent || tempElement.innerText || "";

      extractedText = extractedText.replace(/(\r\n|\n|\r)/gm, "");

      toast.error(extractedText.trim());
    } else {
      toast.error(error?.message || error);
    }
  } catch (e) {
    toast.error("Something went wrong!");
  }
};

export const successHandler = (success) => toast.success(success?.message);
