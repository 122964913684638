import { useNavigate, useLocation } from "react-router-dom";

const useRoutes = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { state, pathname } = location;

  /**
   * Get route state
   */
  const getState = () => state;

  /**
   * Get route pathname
   */
  const getPathname = () => pathname;

  /**
   * Programmatically navigate to a route
   * @param route Route name
   * @param state? State for route
   */
  const navigateTo = (route: string | number, state = {}) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    navigate(route, state);
  };

  return { getState, getPathname, navigateTo };
};

export default useRoutes;
