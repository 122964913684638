import * as React from "react";

import { Generic } from "src/@types/Category";

import agent from "src/helpers/apiAgent";
import { successHandler, errorHandler } from "src/helpers/responseHandler";

import { PROTECTED_ROUTES } from "src/constants/Navigation";

import useRoutes from "src/hooks/useRoutes";
import useNewAssignment from "src/hooks/useNewAssignment";

// Set defaults for reuse
const DEFAULTS = {
  loading: false,
  questionData: {
    keywords: [],
    courseCode: "",
    questionType: 1,
    questionCategory: 1,
    numberOfQuestions: 1,
    questionDificulty: 1,
    bloomsTaxonomy: "create",
  },
  generatedQuestions: [],
  subject: { id: "", name: "" },
  onCancel: () => {},
  resetQuestionData: () => {},
  onSubmitQuestions: async () => {},
  onGenerateQuestions: async () => {},
  setSubject: (subject: Generic) => {},
  onRegenerateQuestions: async () => {},
  setQuestionData: (data: Generic) => {},
  addQuestionTags: (value: string) => {},
  removeQuestionTag: (value: string) => {},
  handleGeneratedDelete: (data: Generic) => {},
  handleRegenerateQuestion: (data: Generic) => {},
  handleEditGeneratedQuestion: (data: Generic, index: number) => {},
  //   editQuestion: (data: Generic, subject: Generic) => {},
};

const BulkQuestionContext = React.createContext(DEFAULTS);

const BulkQuestionProvider: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
}) => {
  const { navigateTo } = useRoutes();
  const { setRefetch } = useNewAssignment();

  const [loading, setLoading] = React.useState(DEFAULTS.loading);
  const [subject, setSubject] = React.useState(DEFAULTS?.subject);
  const [questionData, setQuestionData] = React.useState(DEFAULTS.questionData);
  const [generatedQuestions, setGeneratedQuestions] = React.useState(
    DEFAULTS.generatedQuestions
  );

  const resetQuestionData = () => setQuestionData(DEFAULTS?.questionData);

  //   const editQuestion = (data, subject) => {
  //     const extractedData = {};
  //     Object.keys(DEFAULTS?.questionData).forEach(
  //       (key) => (extractedData[key] = data[key] ?? DEFAULTS.questionData[key])
  //     );
  //     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //     // @ts-ignore
  //     setQuestionData(extractedData);
  //     navigateTo(PROTECTED_ROUTES.ADD_QUESTIONS, { state: { subject } });
  //   };

  const addQuestionTags = (val: string) => {
    // const index = questionData?.keywords.indexOf(val, 0);
    // if (index > -1) {
    //   const updated = [...questionData?.keywords];
    //   updated.splice(index, 1);
    //   setQuestionData((prev) => ({ ...prev, keywords: updated }));
    // } else {
    //   setQuestionData((prev) => ({
    //     ...prev,
    //     keywords: [...questionData?.keywords, val],
    //   }));
    // }
    setQuestionData((prev) => ({ ...prev, keywords: [val] }));
  };

  const removeQuestionTag = (val: string) => {
    // const index = questionData?.keywords.indexOf(val, 0);
    // if (index > -1) {
    //   const updated = [...questionData?.keywords];
    //   updated.splice(index, 1);
    //   setQuestionData((prev) => ({ ...prev, tags: updated }));
    // }
    setQuestionData((prev) => ({ ...prev, keywords: [] }));
  };

  const handleGeneratedDelete = (question) => {
    const index = generatedQuestions?.findIndex(
      (gq) =>
        gq?.questionNo === question?.questionNo &&
        gq?.questionText === question?.questionText
    );

    if (index > -1) {
      const updated = [...generatedQuestions];
      updated.splice(index, 1);
      setGeneratedQuestions(updated);
    }
  };

  const handleRegenerateQuestion = async (question) => {
    try {
      // checking data validity
      if (isQuestionDataValidForGenerating()) {
        setLoading(true);
        const response = await agent.Questions.bulkRgenerateQuestions({
          ...{ ...questionData, numberOfQuestions: 1 },
          questions: [question],
        });

        if (response?.code === 200) {
          const copyOfQuestions = JSON.parse(
            JSON.stringify(generatedQuestions)
          );
          for (let i = 0; i < copyOfQuestions.length; i++) {
            if (
              copyOfQuestions[i].questionNo === question?.questionNo &&
              copyOfQuestions[i].questionText === question?.questionText
            ) {
              if (response?.data?.length > 1) {
                copyOfQuestions[i] = { ...response?.data?.[0] };
              } else {
                copyOfQuestions[i] = { ...response?.data };
              }
              break;
            }
          }
          setGeneratedQuestions(copyOfQuestions);
        }
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const isQuestionDataValidForGenerating = () => {
    if (
      questionData?.numberOfQuestions > 10 ||
      questionData?.numberOfQuestions < 1
    ) {
      errorHandler("Please provide number of questions between 1 to 10!");
      return false;
    }
    if (!questionData?.courseCode) {
      errorHandler("Please select a course!");
      return false;
    }
    if (!questionData?.questionType) {
      errorHandler("Please select question type!");
      return false;
    }
    if (!questionData?.questionCategory) {
      errorHandler("Please select question bank!");
      return false;
    }
    if (questionData?.keywords?.length === 0) {
      errorHandler("Please provide the AI Tags for the questions!");
      return false;
    }
    if (!questionData?.bloomsTaxonomy) {
      errorHandler("Please provide the Bloom's Taxonomy for the questions!");
      return false;
    }
    if (!questionData?.questionDificulty) {
      errorHandler("Please provide the difficulty level of the questions!");
      return false;
    }

    return true;
  };

  const onSubmitQuestions = async () => {
    // Making decision to update or create question
    try {
      // checking data validity
      setLoading(true);
      const response = await agent.Questions.bulkCreateQuestions(
        generatedQuestions
      );

      if (response.code === 201) {
        successHandler(response);
        setRefetch((prev) => !prev);
        navigateTo(PROTECTED_ROUTES.QUESTIONS_LISTING, {
          state: { subject },
        });
        onCancel();
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const onGenerateQuestions = async () => {
    try {
      // checking data validity
      if (isQuestionDataValidForGenerating()) {
        setLoading(true);
        const response = await agent.Questions.bulkGenerateQuestions(
          questionData
        );

        if (response?.code === 200) {
          // Adding question bank details to each question
          const generatedQuestionsWithCategory = response?.data?.map((bq) => ({
            ...bq,
            questionCategory: questionData?.questionCategory,
          }));
          setGeneratedQuestions(generatedQuestionsWithCategory);
          navigateTo(PROTECTED_ROUTES.BULK_QUESTIONS_LISTING, {
            state: { subject },
          });
        }
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const onRegenerateQuestions = async () => {
    try {
      // checking data validity
      if (isQuestionDataValidForGenerating()) {
        setLoading(true);
        const response = await agent.Questions.bulkRgenerateQuestions({
          ...questionData,
          questions: generatedQuestions,
        });

        if (response?.code === 200) {
          setGeneratedQuestions(response?.data);
        }
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const onCancel = () => {
    resetQuestionData();
    navigateTo(PROTECTED_ROUTES.QUESTIONS_LISTING, { state: { subject } });
  };

  const handleEditGeneratedQuestion = (updatedQuestion, index) => {
    const copyGeneratedQuestions = JSON.parse(
      JSON.stringify(generatedQuestions)
    );
    if (index > -1) {
      copyGeneratedQuestions[index] = updatedQuestion;
      setGeneratedQuestions(copyGeneratedQuestions);
      navigateTo(-1);
    }
  };

  const contextValues = {
    loading,
    subject,
    onCancel,
    setSubject,
    questionData,
    // editQuestion,
    addQuestionTags,
    setQuestionData,
    onSubmitQuestions,
    resetQuestionData,
    removeQuestionTag,
    generatedQuestions,
    onGenerateQuestions,
    onRegenerateQuestions,
    handleGeneratedDelete,
    handleRegenerateQuestion,
    handleEditGeneratedQuestion,
  };

  return (
    <BulkQuestionContext.Provider value={contextValues}>
      {children}
    </BulkQuestionContext.Provider>
  );
};

export { BulkQuestionContext };
export default BulkQuestionProvider;
