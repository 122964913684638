import * as React from "react";

import { Generic } from "src/@types/Category";
import { LOCAL_ACTIVE_GRADE } from "src/constants/StorageKeys";

import agent from "src/helpers/apiAgent";

import useAuth from "src/hooks/useAuth";

// Set defaults for reuse
const DEFAULTS = {
  activeCourseId: "",
  loaders: {
    grades: false,
    courses: false,
    categories: false,
  },
  activeGrade: { id: "", name: "" },
  profileGrades: [],
  courses: [],
  categories: {},
  setActiveCourseId: (courseId: string) => {},
  setRefetch: (val: Generic) => {},
  getCategories: () => {},
  updateActiveGrade: (grade) => {},
};
const CourseContext = React.createContext(DEFAULTS);

const CourseProvider: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
  children,
}) => {
  const { isLoggedIn } = useAuth();

  const [refetch, setRefetch] = React.useState(false);
  const [loaders, setLoaders] = React.useState(DEFAULTS.loaders);
  const [activeGrade, setActiveGrade] = React.useState(DEFAULTS.activeGrade);
  const [profileGrades, setProfileGrades] = React.useState(
    DEFAULTS.profileGrades
  );
  const [activeCourseId, setActiveCourseId] = React.useState(
    DEFAULTS.activeCourseId
  );
  const [courses, setCourses] = React.useState(DEFAULTS.courses);
  const [categories, setCategories] = React.useState(DEFAULTS.categories);

  React.useEffect(() => {
    const getGrades = async () => {
      try {
        setLoaders((prev) => ({ ...prev, grades: true }));
        const response = await agent.Auth.profileGrades();

        if (response.code === 200) {
          setProfileGrades(response?.data);

          // Setting previously selected grade
          let previouslyActiveGrade: string | Generic =
            localStorage.getItem(LOCAL_ACTIVE_GRADE);

          if (previouslyActiveGrade !== null) {
            previouslyActiveGrade = JSON.parse(
              previouslyActiveGrade
            ) as Generic;
            setActiveGrade(previouslyActiveGrade);
          } else {
            setActiveGrade(response?.data?.[0]);
          }
        }
      } catch (error) {
      } finally {
        setLoaders((prev) => ({ ...prev, grades: false }));
      }
    };
    if (isLoggedIn) {
      getGrades();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  React.useEffect(() => {
    const getCourses = async () => {
      try {
        setLoaders((prev) => ({ ...prev, courses: true }));
        const response = await agent.Auth.profileCoursesByGradeId(
          activeGrade?.id
        );

        if (response.code === 200) {
          setCourses(response?.data);
        }
      } catch (error) {
      } finally {
        setLoaders((prev) => ({ ...prev, courses: false }));
      }
    };
    if (isLoggedIn && activeGrade?.id && Object?.keys(activeGrade)?.length) {
      getCourses();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, activeGrade, refetch]);

  const getCategories = React.useCallback(async () => {
    try {
      setLoaders((prev) => ({ ...prev, categories: true }));
      const promises = courses.map((course) =>
        agent.Course.getCategoriesInCourse(course?.id)
      );
      const response = await Promise.all(promises);
      const _categories = {};
      if (response.some((res) => res?.code === 200)) {
        response?.forEach((course, idx) => {
          // Replacing parent id null with 0
          course?.data?.categories?.forEach((cat) => {
            if (!cat.parent) {
              cat.parent = 0;
            }
          });

          // creating categories with course id
          _categories[courses[idx]?.id] = {
            categories: course?.data?.categories,
          };
        });
        setCategories(_categories);
      }
    } catch (error) {
    } finally {
      setLoaders((prev) => ({ ...prev, categories: false }));
    }
  }, [courses]);

  React.useEffect(() => {
    if (isLoggedIn && courses?.length) {
      getCategories();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courses]);

  const updateActiveGrade = (grade) => {
    setActiveGrade(grade);
    localStorage.setItem(LOCAL_ACTIVE_GRADE, JSON.stringify(grade));
  };

  const contextValues = {
    activeCourseId,
    activeGrade,
    profileGrades,
    courses,
    loaders,
    setRefetch,
    categories,
    getCategories,
    setActiveCourseId,
    updateActiveGrade,
  };

  return (
    <CourseContext.Provider value={contextValues}>
      {children}
    </CourseContext.Provider>
  );
};

export { CourseContext };
export default CourseProvider;
