import { Toaster } from "react-hot-toast";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import MainRoutes from "./Router";
import agent from "src/helpers/apiAgent";
import { SomeUserLoginDetails } from "src/@types/User";

import AuthProvider from "src/providers/Auth";
import DashboardProvider from "./providers/Dashboard";
import CourseProvider from "src/providers/Course";
import NewLectureProvider from "src/providers/NewLecture";
import NewQuestionProvider from "src/providers/NewQuestion";
import BulkQuestionProvider from "src/providers/BulkQuestions";
import NewAssignmentProvider from "src/providers/NewAssignment";

import { LOCAL_USER } from "./constants/StorageKeys";

const theme = createTheme({
  typography: {
    fontFamily: ["Avenir-Regular"].join(","),
  },
});

// *** Setting up agent token *** //
let userData: string | SomeUserLoginDetails = localStorage.getItem(LOCAL_USER);
if (userData !== null) {
  userData = JSON.parse(userData) as SomeUserLoginDetails;
  agent.setToken(userData?.token);
}
// *** Setting up agent token *** //

const App = () => {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <CourseProvider>
            <DashboardProvider>
              <NewAssignmentProvider>
                <NewLectureProvider>
                  <NewQuestionProvider>
                    <BulkQuestionProvider>
                      <MainRoutes />
                    </BulkQuestionProvider>
                  </NewQuestionProvider>
                </NewLectureProvider>
              </NewAssignmentProvider>
            </DashboardProvider>
          </CourseProvider>
        </AuthProvider>
        <Toaster position="top-center" reverseOrder={false} />
      </ThemeProvider>
    </Router>
  );
};

export default App;
